import React from 'react';
import { Link } from 'gatsby';
import { Logo } from './logo';
import { useLocation } from '@reach/router';
import '../styles/header.css';

export function Header({ next, prev }) {
  const { pathname } = useLocation();
  return (
    <header
      className={`header ${
        pathname === '/' ? 'grid-cols-1 md:grid-cols-1' : 'split'
      }`}
    >
      <Link to="/" className="logo">
        <Logo className="h-8" />
      </Link>
      {pathname !== '/' && (
        <div className="w-full flex items-center px-4">
          <ul className="w-full grid grid-cols-2 items-center list-none gap-3 m-0">
            <li className="justify-self-start w-full overflow-ellipsis whitespace-nowrap overflow-hidden">
              {prev && (
                <>
                  <Link to={prev.slug} className="no-underline hover:underline">
                    <span className="pr-4">←</span>
                    {prev.name}
                  </Link>
                </>
              )}
            </li>
            <li className="justify-self-end text-right w-full overflow-ellipsis whitespace-nowrap overflow-hidden">
              {next && (
                <>
                  <Link to={next.slug} className="no-underline hover:underline">
                    {next.name}
                    <span className="pl-4">→</span>
                  </Link>
                </>
              )}
            </li>
          </ul>
        </div>
      )}
    </header>
  );
}
// export function Header({ next, prev }) {
//   const { pathname } = useLocation();
//   return (
//     <header
//       className={`header ${
//         pathname === '/' ? 'grid-cols-1 md:grid-cols-1' : '.split'
//       }`}
//     >
//       <Link to="/" className="logo">
//         <Logo className="h-8" />
//       </Link>
//       {pathname !== '/' && (
//         <div className="w-full flex items-center px-4">
//           <ul className="w-full flex flex-row justify-between items-center list-none m-0">
//             <li>
//               {prev && (
//                 <>
//                   <Link to={prev.slug} className="no-underline hover:underline">
//                     {prev.name}
//                   </Link>
//                   <span className="pr-4">←</span>
//                 </>
//               )}
//             </li>
//             <li>
//               <Link to={next.slug} className="no-underline hover:underline">
//                 {next.name}
//                 <span className="pl-4">→</span>
//               </Link>
//             </li>
//           </ul>
//         </div>
//       )}
//     </header>
//   );
// }
