import React from 'react';
import { Header } from './header';
import { Footer } from './footer';

function Layout({
  children,
  className = '',
  headerClassName = '',
  footerClassName = '',
  next,
  prev,
}) {
  return (
    <div className={`mx-auto ${headerClassName}`}>
      <Header next={next} prev={prev} />
      <main className={`h-full ${className}`}>{children}</main>
      <Footer footerClassname={footerClassName} />
    </div>
  );
}

export default Layout;
