import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import Layout from '../components/layout';
import { Seo } from '../components/seo';

export default function IndexPage({ data }) {
  const { nodes } = data.allMdx;
  const next = {
    slug: nodes[0].slug,
    name: nodes[0].frontmatter.title,
  };

  return (
    <Layout className="m-4 max-w-3xl mx-auto px-4" next={next}>
      <Seo />
      <section className="w-full my-12 md:my-16 flex justify-center">
        <StaticImage
          src={`../images/banner-xl.png`}
          alt="MMP Timeline Banner Xl"
          placeholder="tracedSVG"
          layout="constrained"
          width={865}
          height={480}
          quality={100}
          objectFit="contain"
          className="max-w-3xl"
        />
      </section>
      <ul className="list-none flex flex-wrap gap-4 justify-center ">
        {nodes.map((page) => (
          <li
            key={page.slug}
            className="text-base w-full min-h-fit cursor-pointer rounded-sm border border-gray-400 bg-white dark:bg-gray-900 dark:border-gray-300 hover:border-gray-800 hover:bg-gray-100 hover:dark:border-gray-100 hover:dark:bg-gray-800 transition-colors duration-200"
          >
            <a
              href={`/${page.slug}`}
              className="flex flex-col items-start justify-center no-underline py-3 px-4 w-full h-full"
            >
              <h2 className="text-xl mb-1">{page.frontmatter.title}</h2>
              <p className="mb-0">{page.excerpt}</p>
            </a>
          </li>
        ))}
      </ul>
    </Layout>
  );
}

export const query = graphql`
  query INDEX_QUERY {
    allMdx(
      filter: { frontmatter: { type: { ne: "timeline" } } }
      sort: { fields: frontmatter___title }
    ) {
      nodes {
        slug
        excerpt(pruneLength: 70)
        frontmatter {
          title
        }
      }
    }
  }
`;
