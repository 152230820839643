import React from 'react';

export function Logo({ style, className, ...rest }) {
  return (
    <svg
      viewBox="0 0 2398 391"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
      {...rest}
    >
      <path
        d="m0 14.992c0-8.28 6.7136-14.992 14.995-14.992h100.06c7.84 0 14.357 6.039 14.952 13.856l21.179 278.45v56.683h13.34v-56.683l21.179-278.45c0.594-7.8164 7.111-13.855 14.952-13.855h100.06c8.281 0 14.995 6.7123 14.995 14.992v359.02c0 8.28-6.714 14.992-14.995 14.992h-36.709c-8.281 0-14.995-6.712-14.995-14.992v-245.08l6.67-88.915h-13.34l-27.764 335.23c-0.643 7.774-7.142 13.755-14.944 13.755h-82.487c-7.783 0-14.273-5.954-14.94-13.708l-28.835-335.28h-13.34l6.6698 88.915v245.08c0 8.28-6.7136 14.992-14.995 14.992h-36.708c-8.2817 0-14.995-6.712-14.995-14.992v-359.02z"
        fill="currentColor"
      />
      <path
        d="m368.32 14.992c0-8.28 6.713-14.992 14.995-14.992h100.06c7.841 0 14.358 6.039 14.953 13.856l21.179 278.45v56.683h13.339v-56.683l21.179-278.45c0.595-7.8164 7.112-13.855 14.953-13.855h100.06c8.282 0 14.995 6.7123 14.995 14.992v359.02c0 8.28-6.713 14.992-14.995 14.992h-36.708c-8.282 0-14.995-6.712-14.995-14.992v-245.08l6.669-88.915h-13.339l-27.764 335.23c-0.644 7.774-7.142 13.755-14.944 13.755h-82.487c-7.784 0-14.273-5.954-14.94-13.708l-28.836-335.28h-13.339l6.669 88.915v245.08c0 8.28-6.713 14.992-14.995 14.992h-36.708c-8.282 0-14.995-6.712-14.995-14.992v-359.02z"
        fill="currentColor"
      />
      <path
        d="m804.14 242.29v131.72c0 8.28-6.714 14.992-14.995 14.992h-36.381c-8.281 0-14.995-6.712-14.995-14.992v-359.02c0-8.28 6.714-14.992 14.995-14.992h162.29c20.009 0 37.425 3.3343 52.247 10.003 14.822 6.2981 27.05 14.819 36.689 25.563 10 10.373 17.41 22.414 22.23 36.121 5.19 13.337 7.78 27.045 7.78 41.123v13.337c0 14.449-2.59 28.712-7.78 42.79-4.82 13.708-12.23 26.119-22.23 37.233-9.639 10.744-21.867 19.45-36.689 26.119-14.822 6.668-32.238 10.002-52.247 10.002h-110.92zm0-60.025h103.69c28.259 0 62.8-19.35 62.8-57.786v-6.669c0-39.851-34.541-54.787-62.8-54.787h-103.69v119.24z"
        fill="currentColor"
      />
      <path
        d="m1102 244.51h42.35v-58.596h36.3v58.596h51.57v34.468h-51.57v64.915c0 5.744 2.6 8.617 7.78 8.617h38.61v34.468h-60.5c-6.53 0-11.91-2.107-16.14-6.319-4.03-4.213-6.05-9.575-6.05-16.086v-85.595h-42.35v-34.468z"
        fill="currentColor"
      />
      <path
        d="m1272.1 352.51h48.4v-73.532h-44.94v-34.468h81.24v108h41.49v34.468h-126.19v-34.468zm93.64-150.22c0 3.83-0.77 7.468-2.31 10.915-1.34 3.255-3.26 6.128-5.76 8.617-2.5 2.298-5.48 4.213-8.93 5.745-3.27 1.34-6.73 2.01-10.37 2.01-3.85 0-7.4-0.67-10.66-2.01-3.27-1.532-6.15-3.447-8.65-5.745-2.49-2.489-4.51-5.362-6.05-8.617-1.34-3.447-2.01-7.085-2.01-10.915s0.67-7.372 2.01-10.627c1.54-3.447 3.56-6.32 6.05-8.617 2.5-2.49 5.38-4.405 8.65-5.745 3.26-1.532 6.81-2.298 10.66-2.298 3.64 0 7.1 0.766 10.37 2.298 3.45 1.34 6.43 3.255 8.93 5.745 2.5 2.297 4.42 5.17 5.76 8.617 1.54 3.255 2.31 6.797 2.31 10.627z"
        fill="currentColor"
      />
      <path
        d="m1576.4 386.98h-36.3v-99.958c0-4.021-1.06-6.989-3.17-8.904-1.92-2.106-4.51-3.16-7.78-3.16-3.84 0-6.91 1.149-9.22 3.447-2.11 2.298-3.17 5.745-3.17 10.341v98.234h-36.3v-99.958c0-4.021-1.15-6.989-3.46-8.904-2.11-2.106-4.7-3.16-7.78-3.16-3.45 0-6.33 1.149-8.64 3.447-2.3 2.298-3.46 5.745-3.46 10.341v98.234h-36.3v-142.47h36.3v13.787h5.19c1.15-5.17 3.74-9.383 7.78-12.638 4.22-3.447 9.7-5.171 16.42-5.171 6.34 0 11.72 1.724 16.13 5.171 4.42 3.255 7.4 7.468 8.94 12.638h5.18c1.54-5.17 4.71-9.383 9.51-12.638 4.99-3.447 11.14-5.171 18.44-5.171 9.6 0 17.28 3.256 23.05 9.766 5.76 6.319 8.64 15.128 8.64 26.426v110.3z"
        fill="currentColor"
      />
      <path
        d="m1639.4 327.52c0.19 4.213 1.15 8.139 2.88 11.777 1.92 3.447 4.42 6.511 7.49 9.191 3.27 2.49 6.92 4.5 10.95 6.032 4.23 1.341 8.64 2.011 13.25 2.011 9.03 0 15.95-1.532 20.75-4.596 4.8-3.255 8.26-7.085 10.37-11.489l31.12 17.234c-1.73 3.638-4.13 7.468-7.21 11.489-3.07 3.83-7.1 7.373-12.1 10.628-4.8 3.255-10.75 5.936-17.86 8.042-6.92 2.107-15.08 3.16-24.49 3.16-10.76 0-20.55-1.723-29.39-5.17-8.83-3.447-16.52-8.426-23.05-14.936-6.33-6.511-11.33-14.458-14.98-23.841-3.45-9.383-5.18-20.01-5.18-31.883v-1.723c0-10.915 1.82-20.873 5.47-29.873 3.84-9 9.03-16.659 15.56-22.978s14.12-11.202 22.76-14.649c8.83-3.638 18.25-5.458 28.23-5.458 12.3 0 22.86 2.202 31.7 6.607 8.83 4.213 16.13 9.67 21.89 16.372 5.76 6.511 9.99 13.787 12.68 21.83 2.69 7.851 4.03 15.511 4.03 22.979v19.244h-104.87zm34.29-54.287c-9.22 0-16.71 2.394-22.48 7.181-5.76 4.596-9.5 9.957-11.23 16.085h67.41c-1.15-6.702-4.8-12.255-10.94-16.66-5.96-4.404-13.54-6.606-22.76-6.606z"
        fill="currentColor"
      />
      <path
        d="m1770 352.51h50.13v-132.13h-48.4v-34.468h84.7v166.6h46.68v34.468h-133.11v-34.468z"
        fill="currentColor"
      />
      <path
        d="m1936.8 352.51h48.41v-73.532h-44.95v-34.468h81.25v108h41.48v34.468h-126.19v-34.468zm93.64-150.22c0 3.83-0.77 7.468-2.31 10.915-1.34 3.255-3.26 6.128-5.76 8.617-2.5 2.298-5.47 4.213-8.93 5.745-3.27 1.34-6.72 2.01-10.37 2.01-3.84 0-7.4-0.67-10.66-2.01-3.27-1.532-6.15-3.447-8.65-5.745-2.49-2.489-4.51-5.362-6.05-8.617-1.34-3.447-2.01-7.085-2.01-10.915s0.67-7.372 2.01-10.627c1.54-3.447 3.56-6.32 6.05-8.617 2.5-2.49 5.38-4.405 8.65-5.745 3.26-1.532 6.82-2.298 10.66-2.298 3.65 0 7.1 0.766 10.37 2.298 3.46 1.34 6.43 3.255 8.93 5.745 2.5 2.297 4.42 5.17 5.76 8.617 1.54 3.255 2.31 6.797 2.31 10.627z"
        fill="currentColor"
      />
      <path
        d="m2134 386.98h-36.31v-142.47h36.31v21.255h5.18c3.27-8.043 8.55-14.266 15.85-18.67 7.3-4.405 15.84-6.607 25.64-6.607 6.91 0 13.35 1.149 19.3 3.447 6.15 2.107 11.53 5.458 16.14 10.053 4.61 4.596 8.16 10.437 10.66 17.522 2.69 7.085 4.03 15.51 4.03 25.276v90.192h-36.3v-81c0-10.149-2.5-18.096-7.49-23.841-4.8-5.936-11.81-8.904-21.03-8.904-10.76 0-18.83 3.638-24.21 10.915-5.18 7.085-7.77 16.66-7.77 28.723v74.107z"
        fill="currentColor"
      />
      <path
        d="m2293.1 327.52c0.19 4.213 1.15 8.139 2.88 11.777 1.92 3.447 4.42 6.511 7.49 9.191 3.27 2.49 6.92 4.5 10.95 6.032 4.22 1.341 8.64 2.011 13.25 2.011 9.03 0 15.94-1.532 20.75-4.596 4.8-3.255 8.25-7.085 10.37-11.489l31.11 17.234c-1.73 3.638-4.13 7.468-7.2 11.489-3.07 3.83-7.11 7.373-12.1 10.628-4.8 3.255-10.76 5.936-17.86 8.042-6.92 2.107-15.08 3.16-24.49 3.16-10.76 0-20.55-1.723-29.39-5.17-8.83-3.447-16.52-8.426-23.05-14.936-6.34-6.511-11.33-14.458-14.98-23.841-3.46-9.383-5.18-20.01-5.18-31.883v-1.723c0-10.915 1.82-20.873 5.47-29.873 3.84-9 9.03-16.659 15.56-22.978s14.11-11.202 22.76-14.649c8.83-3.638 18.24-5.458 28.23-5.458 12.29 0 22.86 2.202 31.69 6.607 8.84 4.213 16.14 9.67 21.9 16.372 5.76 6.511 9.99 13.787 12.68 21.83 2.69 7.851 4.03 15.511 4.03 22.979v19.244h-104.87zm34.28-54.287c-9.22 0-16.71 2.394-22.47 7.181-5.76 4.596-9.51 9.957-11.23 16.085h67.41c-1.15-6.702-4.8-12.255-10.95-16.66-5.95-4.404-13.54-6.606-22.76-6.606z"
        fill="currentColor"
      />
    </svg>
  );
}
