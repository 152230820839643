import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSiteMetadata } from '../hooks/useMetadata';

export function Seo({
  title,
  titleTemplate,
  description,
  image,
  pathname,
  article = false,
}) {
  const {
    description: defaultDescription,
    siteTitle: defaultTitle,
    titleTemplate: defaultTemplate,
    image: defaultImage,
    siteUrl,
    siteLanguage,
    siteLocale,
    author,
    twitterUsername,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    titleTemplate:
      titleTemplate || defaultTemplate || 'the info page for the MMP',
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: pathname || siteUrl,
    siteLanguage: siteLanguage ? siteLanguage : 'en-US',
    article: article ? 'article' : 'website',
  };
  // console.log('defaultTemplate :', seo.titleTemplate);

  return (
    <>
      <Helmet title={seo.title} titleTemplate={`%s | ${seo.titleTemplate}`}>
        <html lang={siteLanguage ? siteLanguage : 'en'} />
        <link rel="canonical" href={seo.url} />
        <meta name="description" content={seo.description} />
        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/apple/285/love-you-gesture_1f91f.png`}
        />
        <link rel="manifest" href={`${siteUrl}/manifest.json`} />
        <meta name="theme-color" content="#F9FAFB" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {seo.image && (
        <>
          <Facebook
            desc={seo.description}
            image={seo.image}
            title={seo.title}
            type={seo.article}
            url={pathname}
            locale={siteLocale ? siteLocale : 'de_at'}
          />
          <Twitter
            title={seo.title}
            image={seo.image}
            desc={seo.description}
            username={twitterUsername}
          />
        </>
      )}
    </>
  );
}

export const Facebook = ({ url, type, title, desc, image, locale }) => (
  <Helmet>
    <meta property="og:locale" content={locale} />
    <meta property="og:url" content={url} />
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={desc} />
    <meta property="og:image" content={image} />
    <meta property="og:image:alt" content={desc} />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
  </Helmet>
);

export const Twitter = ({
  type = 'summary_large_image',
  username,
  title,
  desc,
  image,
}) => (
  <Helmet>
    {username && <meta name="twitter:creator" content={username} />}
    <meta name="twitter:card" content={type} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={desc} />
    <meta name="twitter:image" content={image} />
    <meta name="twitter:image:alt" content={desc} />
  </Helmet>
);
