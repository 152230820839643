import React from 'react';
import '../styles/footer.css';

export function Footer({ footerClassname }) {
  return (
    <footer className={`footer ${footerClassname}`}>
      <p>
        a website by <a href="https://twitter.com/motcodes">@motcodes</a>
      </p>
    </footer>
  );
}
